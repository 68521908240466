import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const SourcesRenewableEnergyForConstruction: React.FC = () => {
  return (
    <>
      <SEO
        title="About sources of renewable energy for construction sites"
        description="Solar power, wind power, and hydrogen power are the main renewable energy sources to power construction sites and reduce emissions."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Top 3 sources of renewable energy for construction sites
        </Typography>
        <Typography variant="body1">
          Getting energy from fossil fuels is already outdated and harmful to
          the environment. An alternative to fossil fuels is installations based
          on renewable energy sources, i.e., natural. Renewable energy sources
          are the energy resources of constantly existing natural processes on
          the planet. A characteristic feature of RES is their inexhaustibility,
          or the ability to restore their potential in a short time.
        </Typography>
        <Typography variant="body1">
          Since ancient times, people have tried to use the power of the wind,
          the energy of the sun, and natural sources of heat for their needs.
          However, these attempts were ineffective due to the lack of sufficient
          knowledge, technologies, and materials.
        </Typography>
        <Typography variant="body1">
          Until recently, the simplest and cheapest way to generate electricity
          and heat in the construction industry was considered to be the use of
          fossil sources: coal, oil, and natural gas. But with the annual
          reduction of hydrocarbon reserves on the planet and the rise in the
          cost of their production, it became necessary to use renewable energy
          sources and efficient energy-saving technologies.
        </Typography>
        <Typography variant="body1">
          But since we live in the 21st century and we have enough knowledge, it
          is possible and necessary to develop the construction of stations for
          generating electricity. For the United States, the development of a
          station for the generation of renewable energy sources is very
          important, because the use of energy-saving and energy-efficient
          technologies will save non-renewable energy resources (oil, gas,
          coal), radically improve the environmental situation, as well as
          reduce the cost and simplify the energy supply of remote construction
          sites from network highways. The development of solar, wind and
          hydroelectric industries may become a priority in this direction.
        </Typography>
        <Typography variant="h2" color="blue">
          Solar energy
        </Typography>
        <Typography variant="body1">
          Solar energy is based on obtaining energy from solar radiation and is
          very promising both for the country as a whole and for the
          construction industry in particular.
        </Typography>
        <Typography variant="body1">
          Solar power plants do not pollute the environment with harmful
          substances. Here, of course, there is a huge potential for solving all
          the problems of world energy. Solar energy is already a serious
          alternative to "traditional" energy.
        </Typography>
        <Typography variant="body1">
          How is this type of renewable energy being implemented in the US?
          Volvo makes loaders and excavators powered by solar energy.
        </Typography>
        <Typography variant="body1">
          Such production is a big step towards reducing greenhouse gas
          emissions. After all, it is a heavy construction equipment that is the
          main source of pollution.
        </Typography>
        <Typography variant="body1">
          Green lighting systems can be installed on construction sites. They
          are much more economical than fossil fuel alternatives.
        </Typography>
        <Typography variant="body1">
          Photovoltaic windows are also produced, which reduce greenhouse gas
          emissions. With their help, it is convenient to control the amount of
          light that enters the building.
        </Typography>
        <Typography variant="body1">
          The technology of transparent thermal insulation of buildings and
          solar collectors has been developed here. Thus, large-scale areas have
          been created from the roofs of houses to receive a significant amount
          of energy. On some days, the amount of energy received from solar
          panels by the national power grid exceeds 50% of the daily electricity
          consumption. Even though in the USA it is not always very sunny days.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/sources-renewable-energy-for-construction/image-1.jpg"
            alt="Solar energy"
            title="Solar energy"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Wind Energy
        </Typography>
        <Typography variant="body1">
          The operation of wind farms is based on the use of wind energy to
          generate electricity from wind turbines. Wind turbine blades come in a
          variety of shapes and configurations. The wind energy resource is
          inexhaustible and environmentally friendly, there are no carbon
          dioxide emissions, due to which the greenhouse effect is enhanced.
          There are no additional, auxiliary, or intermediate devices,
          aggregates, and industries operating on fossil fuels. According to the
          conclusion of the Global Wind Energy Council, by 2050, only the
          world's wind energy will reduce annual CO2 emissions by 1.5 billion
          tons.
        </Typography>
        <Typography variant="body1">
          About 60,000 turbines are installed in the United States, which can
          provide the necessary wind energy to all construction sites.
        </Typography>
        <Typography variant="body1">
          The advantages of wind power also get in the possibility of creating
          local energy districts where centralized grid-generating systems are
          located at a considerable distance. This allows you to reduce costs,
          often very high, for laying centralized networks, which is very
          important in remote areas and rural areas. The production of windmills
          is quite cheap, but their power is not very significant and the
          efficiency depends on the weather.
        </Typography>
        <Typography variant="body1">
          The negative is that for the normal efficient operation of a wind
          turbine, a wind speed of 6-7 m/s is required. In the absence of wind
          at construction sites, it is important to have backup sources or
          energy storage devices - batteries, or combined wind-diesel
          installations.
        </Typography>
        <Typography variant="body1">
          In addition, wind farms can interfere with air traffic and radio
          waves. However, modern innovative windmills-rotors are free from these
          shortcomings, they can operate with negligible air movement, and do
          not create harmful ultrasonic and noise effects, which is a definite
          plus for the continuous operation of the construction site.
        </Typography>
        <Typography variant="h2" color="blue">
          Hydroelectric Energy
        </Typography>
        <Typography variant="body1">
          Hydroelectric power plants convert the mechanical energy of water flow
          into electrical energy through hydraulic turbines that drive
          electrical generators. The hydroelectric power plant has the highest
          efficiency when the flow of waterfalls on the turbine from above. For
          these purposes, a dam is being built that raises the water level in
          the river and concentrates the pressure of water at the location of
          the turbines.
        </Typography>
        <Typography variant="body1">
          The negative is that during the construction of the dam a reservoir is
          formed. Water floods huge areas and irreversibly changes the
          environment. Raising the river level by a dam can cause waterlogging,
          salinity, changes in coastal vegetation, and microclimate. It is
          necessary to single out small hydropower, the advantage of which is
          that it is possible to provide electricity locally to construction
          sites, near which the corresponding water arteries are located.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/sources-renewable-energy-for-construction/image-2.jpg"
            alt="Hydroelectric energy"
            title="Hydroelectric energy"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Hydroelectric power is not as convenient as solar power, but
          construction crews can get the energy they need from nearby
          hydroelectric power plants rather than just using generators.
          Hydroelectric power is often available where there is no wind power.
          By switching to renewable energy sources, you increase the
          sustainability of the industry.
        </Typography>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          The use of renewable energy sources is the best way to eliminate
          harmful emissions from construction sites. Therefore, they should be
          used by all construction companies.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default SourcesRenewableEnergyForConstruction
